import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchClient } from '@core/fetchClient';
import { StateKind } from '@common/state';
import { IAmosOrderRequestDto, IAmosOrderResultDto, IAmosSummaryStatusDto, IAmosSummaryStatusRequestDto } from 'contracts/amos';
import { getServerAddress } from '@core/appVariables';

export interface ISearch {
  dateFrom?: number | null,
  dateTo?: number | null,
  searchByValue?: string;
}

export interface IDashboardState {
  status: StateKind;
  orders: IAmosOrderResultDto;
  pager: IPager;
  filter: ISearch;
  orderStatuses: string[];
  summaryStatus: IAmosSummaryStatusDto;
}

export interface IPager {
  offset: number,
  limit: number,
}

const initialState: IDashboardState = {
  status: 'notLoaded',
  orderStatuses: [],
  summaryStatus: {
    totalCount: 0,
    details: []
  },
  filter: {},
  orders: {
    results: [],
    totalCount: 0
  },
  pager: {
    offset: 0,
    limit: 10,
  }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getOrdersAsync = createAsyncThunk(
  'orders/list',
  async (request: IAmosOrderRequestDto) => {
    const response = await fetchClient().post<IAmosOrderRequestDto, IAmosOrderResultDto>(`${getServerAddress('/orders/api')}/orders`, request)

    // The value we return becomes the `fulfilled` action payload
    return response!;
  }
);

export const getSummaryByStatus = createAsyncThunk(
  '/orders/summaryByStatus/{branchGuid}',
  async (request: IAmosSummaryStatusRequestDto) => {
    const response = await fetchClient().post<IAmosSummaryStatusRequestDto, IAmosSummaryStatusDto>(`${getServerAddress('/orders/api')}/status/summary`, request)

    return response!;
  }
);

export const dashboardStore = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   // state.value += action.payload;
    // },

    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pager.offset = action.payload * state.pager.limit;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pager.limit = action.payload;
    },
    setOrdersStatus: (state, action: PayloadAction<string>) => {
      const orderStatus = action.payload;
      state.pager.offset = 0;

      if (state.orderStatuses.some(t => t === orderStatus)) {
        state.orderStatuses = state.orderStatuses.filter(t => t !== orderStatus);
      } else {
        state.orderStatuses = state.orderStatuses.concat([orderStatus]);
      }
    },
    setFilters: (state, action: PayloadAction<ISearch>) => {
      state.filter = { ...action.payload };
      state.pager.offset = 0;
    },
    clearStore: () => {
      return {
        ...initialState,
        filter: { ...initialState.filter },
        orders: { ...initialState.orders },
        summaryStatus: { ...initialState.summaryStatus },
      };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersAsync.pending, (state: IDashboardState) => {
        state.status = 'loading';
      })
      .addCase(getOrdersAsync.fulfilled, (state: IDashboardState, action: PayloadAction<IAmosOrderResultDto>) => {
        state.status = 'loaded';
        state.orders = action.payload;
      })
      .addCase(getOrdersAsync.rejected, (state: IDashboardState, error) => {
        state.status = 'failed';
      })
      .addCase(getSummaryByStatus.pending, (state: IDashboardState) => {
        state.status = 'loading';
      })
      .addCase(getSummaryByStatus.fulfilled, (state: IDashboardState, action: PayloadAction<IAmosSummaryStatusDto>) => {
        state.status = 'loaded';
        state.summaryStatus = action.payload;
      })
      .addCase(getSummaryByStatus.rejected, (state: IDashboardState, error) => {
        state.status = 'failed';
      });
  },
});

export const DashboardActions = dashboardStore.actions;

export default dashboardStore.reducer;
