//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IAmosBranchDto
{
	parentUId?: string;
	storedValue: string;
	displayedValue?: string;
	multiEvents: boolean;
}
export interface IAmosCarrierRateResponseDto
{
	requestGuid: string;
	totalDistance: number;
	totalDistanceUOM: DistanceUOM;
	totalWeight: number;
	totalWeightUOM: WeightUOM;
	carrierRates: IAmosCarrierRateDto[];
	integrationErrors: { [key:string]: string };
}
export interface IAmosCarrierRateDto
{
	id: number;
	currency: CurrencyType;
	total: number;
	carrierName?: string;
	quoteNumber?: string;
	transportationMode?: string;
	serviceType?: string;
	equipment?: string;
	transitDays?: number;
	mcNumber?: string;
	usDotNumber?: string;
}
export interface IAmosCarrierRateRequestDto
{
	customerGuid: string;
	equipment?: string;
	services?: string[];
	items: IAmosCreateOrderItemDto[];
	events: IAmosCreateOrderEventDto[];
}
export interface IAmosCreateOrderDto
{
	specialInstructions?: string;
	equipment?: string;
	orderType?: string;
	customerGuid: string;
	hot: boolean;
	services?: string[];
	events: IAmosCreateOrderEventDto[];
	items: IAmosCreateOrderItemDto[];
	references?: IAmosCreateOrderReferencesDto[];
	rate: IAmosCreateOrderRateDto;
}
export interface IAmosCreateOrderEventDto
{
	eventType: EventType;
	location: IAmosCreateOrderEventLocationDto;
	comments?: string;
	stopNumber?: string;
	firstComeFirstServe?: boolean;
	eventEarlyDate?: Date;
	eventLateDate?: Date;
	appointmentRequired: boolean;
	appointmentNumber?: string;
	appointmentDate?: Date;
	appointmentEarlyTime?: string;
	appointmentLateTime?: string;
	itemSystemIds: string[];
}
export interface IAmosCreateOrderEventLocationDto
{
	locationName: string;
	address1: string;
	address2?: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	latitude: number;
	longitude: number;
	contacts?: IAmosCreateOrderEventLocationContactsDto;
}
export interface IAmosCreateOrderEventLocationContactsDto
{
	contactName?: string;
	phone?: string;
	email?: string;
	phoneExtension?: string;
}
export interface IAmosCreateOrderItemDto
{
	systemId: string;
	itemId: string;
	description?: string;
	weight: number;
	weightUOM: WeightUOM;
	quantity?: number;
	packageType?: PackageType;
	freightClass: FreightClass;
	nmfc?: string;
	dimensionsL?: number;
	dimensionsW?: number;
	dimensionsH?: number;
	dimensionsUOM?: DimensionsUOM;
}
export interface IAmosCreateOrderRateDto
{
	rateRequestGuid: string;
	selectedRateId: number;
	comments?: string;
	email?: string;
	phone?: string;
	fax?: string;
}
export interface IAmosCreateOrderReferencesDto
{
	referenceTypeGuid: string;
	value: string;
}
export interface IAmosCreateOrderResponseDto
{
	orderGuid: string;
}
export interface IAmosDocumentsDto
{
	elements: IAmosDocumentDto[];
}
export interface IAmosDocumentDto
{
	guid: string;
	documentTypeDisplayedValue?: string;
	fileName?: string;
	url?: string;
}
export interface IAmosEventDto
{
	guid: string;
	appointmentNumber?: string;
	index?: number;
	type?: string;
	status: EventStatusEnum;
	distanceToNextStop?: IAmosDistanceDto;
	itemsTotals: IAmosItemsTotalsDto;
	itemGuids: string[];
	eventEarlyDate: Date;
	eventLateDate: Date;
	location: IAmosLocationDto;
	estimatedTimeOfArrival?: Date;
}
export interface IAmosLastStatusMessageListDto
{
	elements: IAmosLastStatusMessageDto[];
}
export interface IAmosLastStatusMessageDto
{
	eventGuid?: string;
	statusDate?: Date;
	statusCodeDisplayedValue?: string;
	statusReasonCodeDisplayedValue?: string;
	infoFromIntegration?: string;
	location: IAmosLocationDto;
}
export interface IAmosLocationDto
{
	name?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	zip?: string;
	country?: string;
	latitude?: number;
	longitude?: number;
}
export interface IAmosLookupDto
{
	serviceList: IAmosSelectableValueDto[];
	equipmentList: IAmosSelectableValueDto[];
	orderTypeList: IAmosSelectableValueDto[];
}
export interface IAmosMapItemsDto
{
	elements: IAmosMapItemDto[];
}
export interface IAmosMapItemDto
{
	guid: string;
	transportationModeStoredValue?: string;
	firstEventLocation?: IAmosMapLocationDto;
	lastEventLocation?: IAmosMapLocationDto;
	lastStatusMessageLocation?: IAmosMapLocationDto;
}
export interface IAmosMapLocationDto
{
	eventGuid?: string;
	latitude?: number;
	longitude?: number;
}
export interface IAmosMapOrderDetailDto
{
	primaryReferenceValue: string;
	transportationModeStoredValue: string;
	events: IAmosMapEventDto[];
	lastStatusMessage?: IAmosLastStatusMessageDto;
}
export interface IAmosMapEventDto
{
	guid: string;
	eventType: string;
	location: IAmosLocationDto;
	eventEarlyDate?: Date;
	eventCompleteDate?: Date;
	eppointmentDate?: Date;
}
export interface IAmosOrderDetailsDto
{
	status: string;
	branchGuid: string;
	primaryReferenceValue: string;
	orderTypeDisplayedValue: string;
	transportationModeStoredValue: string;
	transportationIconType: TransportationIconTypeEnum;
	totalDistance?: number;
	distanceUOM?: string;
	carrier?: IAmosCarrierDto;
	events: IAmosEventDto[];
	items: IAmosItemDto[];
	payment?: IAmosPaymentDto;
	lastStatusMessage?: IAmosLastStatusMessageDto;
}
export interface IAmosItemDto
{
	guid: string;
	itemId: string;
	freightClass: string;
	packageType: string;
	weight?: number;
	weightUOM: string;
	volume?: number;
	volumeUOM?: string;
	dimensionsL?: number;
	dimensionsH?: number;
	dimensionsW?: number;
	dimensionsUOM: string;
	description?: string;
	hazardous: boolean;
	temperatureLow?: number;
	temperatureHigh?: number;
	temperatureUOM?: string;
}
export interface IAmosItemsTotalsDto
{
	packageType: string;
	totalWeight?: number;
	weightUOM: string;
	totalQuantity?: number;
	lowTemperature?: number;
	highTemperature?: number;
	temperatureUOM: string;
	hazardous: boolean;
}
export interface IAmosPaymentDto
{
	paymentTerm?: string;
	mainChargesTotal?: number;
	fuelChargesTotal?: number;
	discountChargesTotal?: number;
	additionalChargesTotal?: number;
	total?: number;
	currency: string;
}
export interface IAmosCarrierDto
{
	name: string;
	proNumber?: string;
}
export interface IAmosDistanceDto
{
	distance?: number;
	distanceUom?: string;
}
export interface IAmosOrderDto
{
	guid: string;
	primaryReferenceValue?: string;
	status: string;
	carrierName: string;
	lastEvent: IAmosEventDto;
	firstEvent: IAmosEventDto;
	rate?: IAmosRateDto;
}
export interface IAmosRateDto
{
	total: number;
	currency: string;
}
export interface IAmosOrderRequestDto
{
	branchGuid: string;
	searchByValue?: string;
	statuses?: string[];
	dateFrom?: Date;
	dateTo?: Date;
	offset: number;
	limit: number;
	locationTypes?: LocationTypeEnum[];
}
export interface IAmosOrderResultDto
{
	totalCount: number;
	results: IAmosOrderDto[];
}
export interface IAmosReferencesDto
{
	elements: IAmosReferenceDto[];
}
export interface IAmosReferenceDto
{
	guid: string;
	name?: string;
	value?: string;
	primary: boolean;
	updatable: boolean;
}
export interface IAmosReferenceType
{
	guid: string;
	name: string;
	allowedValues: string[];
}
export interface IAmosReferenceTypes
{
	elements: IAmosReferenceType[];
}
export interface IAmosSelectableValueDto
{
	storedValue: string;
	displayedValue?: string;
}
export interface IAmosSelectableValueElementsDto
{
	elements: IAmosSelectableValueDto[];
}
export interface IAmosSummaryStatusDto
{
	totalCount: number;
	details: IAmosStatusDto[];
}
export interface IAmosStatusDto
{
	count: number;
	status: string;
}
export interface IAmosSummaryStatusRequestDto
{
	branchGuid: string;
	searchByValue?: string;
	dateFrom?: Date;
	dateTo?: Date;
}
export interface IAmosTransportationMapIconTypeDto
{
	displayedValue: string;
	storedValue: string;
	transportationMode: TransportationMapIconTypeEnum;
}
export interface IAmosReferenceCreateDto
{
	orderGuid: string;
	referenceTypeGuid: string;
	value: string;
}
export interface IAmosReferenceUpdateDto
{
	guid: string;
	value: string;
}
export enum LocationTypeEnum {
	Origin = 0,
	Destination = 1,
	Tracking = 2
}
export enum PermissionTypeEnum {
	CreateOrder = 1,
	ViewRate = 2,
	MultiEvents = 3
}
export enum TransportationMapIconTypeEnum {
	Truck = 1,
	Boat = 2
}
export enum TransportationIconTypeEnum {
	Car = 0,
	Pallet = 1,
	Train = 2,
	Building = 3,
	Money = 4,
	Plane = 5,
	Fast = 6,
	Parcel = 7,
	LargeShipment = 8,
	Container = 9,
	HalfContainer = 10,
	HalfTruck = 11
}
export enum ReportTypeEnum {
	APG = 1,
	LDT = 2,
	SFS = 3,
	WPD = 4,
	AdditionalCharges = 5,
	OrderDetailsAssessorial = 6
}
export enum PaymentTerm {
	THIRD_PARTY = "Third party",
	COLLECT = "Collect",
	PREPAID = "Prepaid",
	DIRECT_PAY = "Direct pay",
	QUICK_PAY = "Quick pay"
}
export enum CurrencyType {
	USD = "$",
	CAD = "CAD",
	EUR = "€",
	MXN = "₱"
}
export enum DimensionsUOM {
	CENTIMETER = "Centimeter",
	METER = "Meter",
	INCH = "Inch",
	FOOT = "Foot"
}
export enum EventType {
	PICKUP = "Pickup",
	DROP = "Drop"
}
export enum EventStatusEnum {
	PENDING = "PENDING",
	ARRIVED = "ARRIVED",
	CHECKED_IN = "CHECKED_IN",
	COMPLETED = "COMPLETED"
}
export enum ItemType {
	ITEM = "Item",
	CONTAINER = "Container"
}
export enum LoadType {
	CLO = "CLO",
	TEL = "TEL"
}
export enum PackageType {
	BOX = "Box",
	CASES = "Cases",
	DRUMS = "Drums",
	CRATES = "Crates",
	GALLON = "Gallon",
	PALLET = "Pallet",
	PIECES = "Pieces",
	BUNDLES = "Bundles",
	CARTONS = "Cartons",
	CONTAINER = "Container",
	SKIDS = "Skids",
	SACK = "Sack",
	BAG = "Bag",
	RACK = "Rack",
	ROLL = "Roll",
	BIN = "Bin",
	TOTE = "Tote",
	GAYLORD = "Gaylord",
	BALES = "Bales",
	PAIL = "Pail",
	TRUCK_LOAD = "Truck Load"
}
export enum TemperatureType {
	FROZEN = "Frozen",
	CHILLED = "Chilled",
	DRY = "Dry",
	HOT = "Hot"
}
export enum TemperatureUOM {
	CELSIUS = "Celsius",
	FAHRENHEIT = "Fahrenheit"
}
export enum VolumeUOM {
	CUBIC_M = "Cubic metre",
	CUBIC_FT = "Cubic feet",
	GALLON_LIQUID = "Gallon liquid",
	LITER = "Liter"
}
export enum WeightUOM {
	KG = "Kilograms",
	LB = "Pounds",
	METRIC_TON = "Metric ton",
	TON_US = "Ton US"
}
export enum FreightClass {
	NoFreightClass = "No Freight Class",
	_050 = "050",
	_055 = "055",
	_060 = "060",
	_065 = "065",
	_070 = "070",
	_077 = "077.5",
	_085 = "085",
	_092 = "092.5",
	_100 = "100",
	_110 = "110",
	_125 = "125",
	_150 = "150",
	_175 = "175",
	_200 = "200",
	_250 = "250",
	_300 = "300",
	_400 = "400",
	_500 = "500"
}
export enum DistanceUOM {
	KM = "Kilometer",
	METER = "Meter",
	MILE = "Mile"
}
