import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardKpiBaseFilterDto, IDashboardKpiModeFilterDto, IDashboardOnTimeDto, IDashboardShipmentsByModeDto, IDashboardShipmentsByMonthDto, IDashboardSpendByMonthDto } from "contracts/dashboard";

interface IKpiStatus {
    onTimeStatus: StateKind;
    shipmentsByModeStatus: StateKind;
    customerByMonthStatus: StateKind;
    shipmentsByMonthStatus: StateKind;
    spendByMonthStatus: StateKind;
}

interface IKpiState {
    chartStatus: IKpiStatus;
    status: StateKind;

    spendByMonth?: IDashboardSpendByMonthDto[];
    customerSpendByMonth?: IDashboardSpendByMonthDto[];
    shipmentsByMonth?: IDashboardSpendByMonthDto[];
    shipmentsByMode?: IDashboardShipmentsByMonthDto[];
    onTime?: IDashboardOnTimeDto;
}

const initialState: IKpiState = {
    status: 'notLoaded',
    chartStatus: {
        onTimeStatus: 'notLoaded',
        shipmentsByModeStatus: 'notLoaded',
        customerByMonthStatus: 'notLoaded',
        shipmentsByMonthStatus: 'notLoaded',
        spendByMonthStatus: 'notLoaded',
    }
}

export const getSpendByMonth = createAsyncThunk(
    'kpi-dashboard/common/spend-by-month',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardSpendByMonthDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/spend-by-month`, request);

        return response;
    }
)

export const getCustomerSpendByMonth = createAsyncThunk(
    'kpi-dashboard/common/customer-spend-by-month',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardSpendByMonthDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/customer-spend-by-month`, request);

        return response;
    }
)

export const getShipmentsByMonth = createAsyncThunk(
    'kpi-dashboard/common/shipments-by-month',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardSpendByMonthDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/spend-by-month`, request);

        return response;
    }
)

export const getShipmentsByMode = createAsyncThunk(
    'kpi-dashboard/common/shipments-by-mode',
    async (request: IDashboardKpiModeFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiModeFilterDto, IDashboardShipmentsByMonthDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/shipments-by-mode`, request);

        return response;
    }
)

export const getOnTime = createAsyncThunk(
    'kpi-dashboard/common/on-time',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardOnTimeDto>(`${getServerAddress('/analytics/api')}/dashboard-kpi/on-time`, request);

        return response;
    }
)

export const kpiStore = createSlice({
    name: 'kpi-dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpendByMonth.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'loading' };
            })
            .addCase(getSpendByMonth.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardSpendByMonthDto[] | undefined>) => {
                state.spendByMonth = action.payload;
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'loaded' };
            })
            .addCase(getSpendByMonth.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'failed' };
            })
            .addCase(getShipmentsByMode.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, shipmentsByModeStatus: 'loading' };
            })
            .addCase(getShipmentsByMode.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardShipmentsByMonthDto[] | undefined>) => {
                state.shipmentsByMode = action.payload;
                state.chartStatus = { ...state.chartStatus, shipmentsByModeStatus: 'loaded' };
            })
            .addCase(getShipmentsByMode.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, shipmentsByModeStatus: 'failed' };
            })
            .addCase(getShipmentsByMonth.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, shipmentsByMonthStatus: 'loading' };
            })
            .addCase(getShipmentsByMonth.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardSpendByMonthDto[] | undefined>) => {
                state.shipmentsByMonth = action.payload;
                state.chartStatus = { ...state.chartStatus, shipmentsByMonthStatus: 'loaded' };
            })
            .addCase(getShipmentsByMonth.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, shipmentsByMonthStatus: 'failed' };
            })
            .addCase(getCustomerSpendByMonth.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, customerByMonthStatus: 'loading' };
            })
            .addCase(getCustomerSpendByMonth.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardSpendByMonthDto[] | undefined>) => {
                state.customerSpendByMonth = action.payload;
                state.chartStatus = { ...state.chartStatus, customerByMonthStatus: 'loaded' };
            })
            .addCase(getCustomerSpendByMonth.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, customerByMonthStatus: 'failed' };
            })
            .addCase(getOnTime.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, onTimeStatus: 'loading' };
            })
            .addCase(getOnTime.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardOnTimeDto | undefined>) => {
                state.onTime = action.payload;
                state.chartStatus = { ...state.chartStatus, onTimeStatus: 'loaded' };
            })
            .addCase(getOnTime.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, onTimeStatus: 'failed' };
            })
    }
});

export const KpiAction = kpiStore.actions;

export default kpiStore.reducer;