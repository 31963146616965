import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { ChartColor } from "app/theme";
import chroma from "chroma-js";

const useAppTheme = () => {
    return useTheme() as Theme;
}

export default useAppTheme;

export  const useColorPalette = (numColors: number) => {
    const theme = useAppTheme();
    const scaleArray = [
        theme.palette.primary.main,
        theme.palette.success.main,
        ChartColor.turquoise,
        theme.palette.info.main,
        theme.palette.secondary.main,
    ];

    return chroma.scale(scaleArray)
        .mode('lch')
        .colors(numColors);
}